<script setup>
    import { ref, onMounted, watch } from 'vue'
    import { useI18n } from 'vue-i18n';
    const { locale } = useI18n();
    let videoUrl = ref(new URL(`/public/assets/videos/es/home_long.webm`, import.meta.url).href)
    if(locale.value === 'en') {
        videoUrl = ref(new URL(`/public/assets/videos/en/home_long.webm`, import.meta.url).href)
    } else
    if(locale.value === 'fr') {
        videoUrl = ref(new URL(`/public/assets/videos/fr/home_long.webm`, import.meta.url).href)
    } else
    if(locale.value === 'ca') {
        videoUrl = ref(new URL(`/public/assets/videos/ca/home_long.webm`, import.meta.url).href)
    } else 
    if(locale.value === 'pt') {
        videoUrl = ref(new URL(`/public/assets/videos/pt/home_long.webm`, import.meta.url).href)
    } 

    watch(locale, () => {
        if(locale.value === 'es') {
            videoUrl.value = new URL(`/public/assets/videos/es/home_long.webm`, import.meta.url).href
        } else 
        if(locale.value === 'fr') {
            videoUrl.value = new URL(`/public/assets/videos/fr/home_long.webm`, import.meta.url).href
        } else 
        if(locale.value === 'ca') {
            videoUrl.value = new URL(`/public/assets/videos/es/home_long.webm`, import.meta.url).href
        } else 
        if(locale.value === 'pt') {
            videoUrl = ref(new URL(`/public/assets/videos/pt/home_long.webm`, import.meta.url).href)
        }
        else {
            videoUrl.value = new URL(`/public/assets/videos/en/home_long.webm`, import.meta.url).href
        }
    });
</script>
<template>
    <section class="w-100 custom-container">
        <div class="custom-wrapper home-powerful">
            <video
                class="home-powerful-video-js"
                controls
                preload="none"
                width="1100"
                height="550"
                controlsList="nodownload"
                aspectRatio="9:16"
                poster="/assets/video.webp"
                data-setup="{}">
                <source :src=videoUrl type="video/webm">
            </video>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.custom-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    background-color: black;
    .home-powerful-video-js {
        object-fit: fill;
        max-width: 1100px;
    }
}

.custom-container {
    padding-top: 0px;
}

@media (max-width: 1000px) { 
    .home-powerful .home-powerful-video-js {
        max-width: 100%;
        max-height: 325px;
    }
    .custom-wrapper.home-powerful {
        display: block;
    }
}
@media (max-width: 500px) { 
    .home-powerful .home-powerful-video-js {
        max-width: 100%;
        max-height: 250px;
    }
}
@media (max-width: 420px) { 
    .home-powerful .home-powerful-video-js {
        max-width: 100%;
        max-height: 245px;
    }
}
@media (max-width: 390px) { 
    .home-powerful .home-powerful-video-js {
        max-width: 100%;
        max-height: 230px;
    }
}

</style>