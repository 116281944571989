<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useI18n } from 'vue-i18n';

const menuOpen = ref(false)
const menuChecked = ref(false)
const navbar = ref();
const { locale } = useI18n();

let location = ref(locale.value);

const englishLocales = ['en', 'fr', 'pt'];
const spanishLocales = ['es', 'ca'];

let blogUrl = ref('https://blog.cloudactivereception.com/' + locale.value)

watch(locale, (newLocale) => {
    if(englishLocales.includes(newLocale)){
        blogUrl.value = 'https://blog.cloudactivereception.com/en';
    }else if (spanishLocales.includes(newLocale)){
        blogUrl.value = 'https://blog.cloudactivereception.com/';
    }else{
        blogUrl.value = 'https://blog.cloudactivereception.com/en';
    }
});

onMounted (() => {
    window.addEventListener('click', closeNavbar);
});

onBeforeUnmount(() => {
    window.removeEventListener('click', closeNavbar);
});


function closeMenu() {
    menuChecked.value = false
    menuOpen.value = false;
}

const closeNavbar = (event) => {
    if(event.targer != navbar.value && !event.composedPath().includes(navbar.value)) {
        closeMenu();
    }
} 
</script>
<template>
    <nav class="navbar navbar-expand-lg fixed-top" ref="navbar">
        <div class="w-100">
            <div class="navbar-wrapper">
                <div class="container ">
                    <div class="container-fluid">
                        <router-link :to="`/${locale}`" class="navbar-brand d-flex">
                            <img src="/assets/logo-white.png" />
                            <div class="navbar-title">cloud active reception</div>
                        </router-link>
                        <div class="navbar-collapse" id="navbarSupportedContent">
                            <div class="navbar-button-content">
                                <div class="navbar-button" @click="menuOpen = !menuOpen">
                                    <div class="navbar-button">
                                        <input type="checkbox" id="menu_checkbox" v-model="menuChecked">
                                        <label for="menu_checkbox" @click="menuOpen = !menuOpen">
                                            <div></div>
                                            <div></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" target="_blank" aria-current="page" href="https://www.linkedin.com/company/cloud-active-reception/">
                                        <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" target="_blank" href="https://www.youtube.com/@carcloudactivereception8918">
                                        <font-awesome-icon icon="fa-brands fa-youtube" />
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" target="_blank" href="https://www.instagram.com/cloudactivereception/">
                                        <font-awesome-icon icon="fa-brands fa-instagram" />
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link link-blog" target="_blank" :href="blogUrl">
                                        <font-awesome-icon icon="fa-solid fa-blog" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <transition name="slide-fade">
                <div class="expanded-menu" v-show="menuOpen">
                    <div class="container expanded-wrapper">
                        <div class="expanded-inner-wrapper">
                            <div class="expanded-block">
                                <div class="expanded-title">
                                    {{ $t('field.explore') }} 
                                </div>
                                <div class="expanded-content">
                                    <div class="expandend-content-block text-uppercase">
                                        <router-link :to="`/${location}/functionalities`" @click="closeMenu">
                                            {{ $t('field.car') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/roi`" @click="closeMenu">
                                            {{ $t('field.roi') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/cases`" @click="closeMenu">
                                            {{ $t('field.success_stories') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/company`" @click="closeMenu">
                                            {{ $t('field.company') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/partners`" @click="closeMenu">
                                            {{ $t('field.partners') }} 
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="expanded-block">
                                <div class="expanded-title">
                                    {{ $t('field.functionalities') }} 
                                </div>
                                <div class="expanded-content">
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/appointments`" @click="closeMenu">
                                            {{ $t('field.digital_appointment') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/receptions`" @click="closeMenu">
                                            {{ $t('field.active_reception') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/reparations`" @click="closeMenu">
                                            {{ $t('field.reparation') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/planner`" @click="closeMenu">
                                            {{ $t('field.planning') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block text-uppercase">
                                        <router-link :to="`/${location}/crm`" @click="closeMenu">
                                            {{ $t('field.crm') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/invoice`" @click="closeMenu">
                                            {{ $t('field.digital_invoice') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/data`" @click="closeMenu">
                                            {{ $t('field.data') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/mobility`" @click="closeMenu">
                                            {{ $t('field.mobility') }} 
                                        </router-link>
                                    </div>
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/gdpr`" @click="closeMenu">
                                            {{ $t('field.rgdp') }} 
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="expanded-block">
                                <div class="expanded-title">
                                    {{ $t('field.contact_single') }} 
                                </div>
                                <div class="expanded-content">
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/contact`" @click="closeMenu">
                                            {{ $t('field.talk_to_us') }} 
                                        </router-link>
                                    </div>
                                </div>
                                <div class="expanded-content">
                                    <div class="expandend-content-block">
                                        <router-link :to="`/${location}/distributor`" @click="closeMenu">
                                            {{ $t('header.distributor') }} 
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
    .navbar {
        padding: 0;
        color: white;
        img {
            width: 50px;
        }
        a {
            color: white;
            div {
                padding-left: 10px;
                padding-top: 9px;
            }
        }
        :focus,
        :hover {
            color: white !important;
        }
        .navbar-nav {
            display: flex;
            justify-items: flex-end;
        }
        .navbar-collapse {
            justify-content: flex-end;
        }
        .navbar-title {
            font-size: 14px;
            font-weight: bold;
        }
    }
    .navbar-button-content {
        padding-right: 20px;
    }
    .navbar-button {
        position: relative;
        border: none;
        width: 50px;
    }
    .navbar-button:focus {
        border: none;
    }
    .navbar-wrapper {
        background-color: black;
        .container {
            padding: .5rem 0rem;
        }
    }
    .nav-link {
        &:hover {
            animation: pulse 1s 1;
            animation-timing-function: linear;     
        }
        &.link-blog {
            font-size: 15px;
        }
    }

    #menu_checkbox {
        display: none;
    }
    
    label {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        margin: 0 auto;
        display: block;
        width: 30px;
        height: 31px;
        transform: translateY(-50%);
        transition: 0.3s ease transform;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }
    
    label div {
        width: 6px;
        height: 3px;
        background-color: white;
        margin-left: 0;
        margin-bottom: 4px;
        border-radius: 0px;
        transition: 0.7s ease width, 0.7s ease margin-left, 0.7s ease margin-bottom,
        0.7s ease background-color;
    }
    
    label div:first-child {
        width: 30px;
    }
    
    label div:nth-child(2) {
        width: 15px;
    }
    
    #menu_checkbox:checked + label {
        transform: translateY(-50%) rotateZ(-90deg);
    }
    
    #menu_checkbox:checked + label div {
        width: 10px;
        margin-bottom: 3px;
        background-color: white;
        &:first-child {
            width: 24px; 
            transform: rotate(45deg);
            margin-bottom: -2px;
        }
        &:last-child {
            width: 24px; 
            transform: rotate(135deg);
            margin-bottom: -2px;
        }
    }

    
    .navbar .container, 
    .navbar .container-lg,
    .navbar .container-md, 
    .navbar .container-sm, 
    .navbar .container-xl, 
    .navbar .container-xxl {
        display: block;
    }
    .navbar .container-fluid {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }
    .expanded-menu {
        padding: 50px 75px;
        background-color: rgba(0, 0, 0, .77);
        backdrop-filter: blur(7px);
        .expanded-wrapper {
            display: flex;
            padding-left: 40px;
            padding-right: 40px;
            .expanded-inner-wrapper {
                display: flex;
                gap: 125px;
            }
            .expanded-title {
                font-size: 32px;
                letter-spacing: -1.6px;
                font-family: 'CircularStd-Black';
                padding-bottom: 25px;
            }
            .expanded-content {
                font-size: 18px;
                letter-spacing: -0.9px;
                display: flex;
                flex-direction: column;
                align-items: start;
                .expandend-content-block {
                    cursor: pointer;
                    position: relative;
                    line-height: 16px;
                    a {
                        text-decoration: none;
                        position: relative;
                        display: block;
                        transition: 0.5s;
                        transition: white 100ms linear;
                        padding-bottom: 9px;
                        &:before {
                            position: absolute;
                            display: block;
                            left: 0.05rem;
                            height: 1px;
                            bottom: 0.5rem;
                            content: "";
                            background: white;
                            -webkit-transition: transform 300ms ease;
                            -moz-transition: transform 300ms ease;
                            transition: transform 300ms ease;
                            -webkit-transform-origin: 50% 50%;
                            -moz-transform-origin: 50% 50%;
                            -ms-transform-origin: 50% 50%;
                            transform-origin: 50% 50%;
                            -webkit-transform: scaleX(0);
                            -moz-transform: scaleX(0);
                            -ms-transform: scaleX(0);
                            transform: scaleX(0);
                            width: 20px;
                        }
                
                        &:hover::before {
                            transform: scaleX(1);
                            transform-origin: left;
                        }
                    }
                }
            }
        } 
    }
    .menu-collapse {
        font-size: 23px;
        cursor: pointer;
    }

    .slide-fade-enter-active {
        transition: all 1s ease-out;
    }
    .slide-fade-leave-active {
        transition: all .1s ease-out;
    }
    .slide-fade-enter-from,
    .slide-fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.3) }
        100% { transform: scale(1); }
    }

    @media (max-width: 1000px) {
        .navbar .navbar-nav {
            display: none;
        }
        #navbarSupportedContent {
            display: flex;
        }
        .expanded-menu {
            padding: 10px 15px;
            height: calc(100vh - 100px);
            .expanded-wrapper {
                padding-left: 10px;
                padding-right: 10px;
                .expanded-inner-wrapper {
                    display: block;
                    .expanded-block {
                        padding-top: 30px;
                        .expanded-title {
                            font-size: 1.5rem;
                        }
                    }
                }
                .expanded-title {
                    font-size: 1.5rem;
                }
            }
        }
    }
    @media (max-width: 390px) {
        .expanded-menu {
            height: calc(100vh - 60px);
            .expanded-wrapper {
                padding-left: 10px;
                padding-right: 10px;
                .expanded-inner-wrapper {
                    display: block;
                    .expanded-block {
                        padding-top: 10px;
                    }
                }
            }
        }
        .expanded-menu .expanded-wrapper .expanded-title {
            padding-bottom: 5px;
        }
    }
</style>